import React from "react";
import { Link } from "react-router-dom";
import Card from 'react-bootstrap/Card';
import CardImg from "react-bootstrap/esm/CardImg";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { CartContext } from "../../Context/CartContext"
import { useContext } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";

function ProductCards(props) {

    const product = props.product;
    const cart = useContext(CartContext);
    const productQuantity = cart.getProductQuantity(product.id);

    if (product.stock > 12) {
        if (product.specialPrice) {
            return (
                <Card className="ProductCards" key={product._id} hoverable="true" style={{ border: "none", marginBottom: "2%" }}>
                    <Link to={`/Shop/${product.id}`}>
                        <Container className="thumbnail">
                            <span className="moreInfo">More Info</span>
                            <CardImg variant="top" src={product.image} className="imgfluid"></CardImg>
                        </Container>
                    </Link>
                    <Card.Body>
                        <Container className="productHeader">
                            <Card.Title><b>{product.name}</b></Card.Title>
                            <Card.Subtitle className="text-muted">{product.year}</Card.Subtitle>
                        </Container>
                        <Card.Text className="productDescription">{product.description}</Card.Text>
                        <Card.Text className="productPrice">£ {product.specialPrice.$numberDecimal}</Card.Text>
                        {productQuantity > 0 ?
                            <>
                                <Row>
                                    <Col>
                                        <span className="inCart">In Cart: {productQuantity}</span>
                                    </Col>
                                    <Col >
                                        <Button onClick={() => cart.addOneToCart(product.id)} className="buttonValueChange">+</Button>
                                        <Button onClick={() => cart.removeOneFromCart(product.id)} className="buttonValueChange">-</Button>
                                    </Col>
                                </Row>
                            </>
                            :
                            <>
                                <Button onClick={() => cart.addOneToCart(product.id)} className="buttonAddtoCart">Add to Basket</Button>
                            </>

                        }
                    </Card.Body>
                </Card >
            )
        }
        else {
            return (
                <Card className="productCards" key={product._id} hoverable="true">
                    <Link to={`/Shop/${product.id}`}>
                        <Container className="thumbnail">
                            <span className="moreInfo">More Info</span>
                            <CardImg variant="top" src={product.image} className="imgfluid"></CardImg>
                        </Container>
                    </Link>
                    <Card.Body>
                        <Container className="productHeader">
                            <Card.Title><b>{product.name}</b></Card.Title>
                            <Card.Subtitle className="text-muted">{product.year}</Card.Subtitle>
                        </Container>
                        <Card.Text className="productDescription">{product.description}</Card.Text>
                        <Card.Text className="productPrice">£ {product.price.$numberDecimal}</Card.Text>
                        {productQuantity > 0 ?
                            <>
                                <Row>
                                    <Col>
                                        <span className="inCart">In Cart: {productQuantity}</span>
                                    </Col>
                                    <Col >
                                        <Button onClick={() => cart.addOneToCart(product.id)} className="buttonValueChange">+</Button>
                                        <Button onClick={() => cart.removeOneFromCart(product.id)} className="buttonValueChange">-</Button>
                                    </Col>
                                </Row>
                            </>
                            :
                            <>
                                <Button onClick={() => cart.addOneToCart(product.id)} className="buttonAddtoCart">Add to Basket</Button>
                            </>

                        }
                    </Card.Body>
                </Card >
            )
        }
    }
    else {
        return (
            <Card className="productCards" key={product._id} hoverable="true">
                <Link to={`/Shop/${product.id}`}>
                    <Container className="thumbnail">
                        <span className="moreInfo">More Info</span>
                        <CardImg variant="top" src={product.image} className="imgfluid"></CardImg>
                    </Container>
                </Link>
                <Card.Body>
                    <Container className="productHeader">
                        <Card.Title><b>{product.name}</b></Card.Title>
                        <Card.Subtitle className="text-muted">{product.year}</Card.Subtitle>
                    </Container>
                    <Card.Text className="productDescription">{product.description}</Card.Text>
                    <Card.Text className="productPrice">Out of Stock</Card.Text>
                </Card.Body>
            </Card >
        )

    }

}

export default ProductCards     