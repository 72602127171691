const productsArray = [
    {
        "_id": {
            "$oid": "63d67bf905e2e1e26cc8a993"
        },
        "name": "Van Loveren Daydream Rosé",
        "image": "/Images/r001.jpg",
        "gallery": {
            "front": "/Images/r001.jpg",
            "close": "/Images/r001-close.jpg"
        },
        "pairing": "Enjoy with fresh oysters, smoked salmon salad or deep-fried Camembert cheese.",
        "accolades": [
            {
                "accolade": "2022 Platter's Wine Guide - 3 stars"
            },
            {
                "accolade": "2022 Double gold Awards"
            },
            {
                "accolade": "2022 LUMO Awards, Gold"
            },
            {
                "accolade": "2022 Merit Awards Wine & Spirit Challenge, Double Gold"
            },
            {
                "accolade": "2021 Old Mutual Trophy Show, Bronze"
            },
            {
                "accolade": "2021 Veritas Wine Awards, Silver"
            },
            {
                "accolade": "2021 The Trophy Wine Show, Bronze"
            },
            {
                "accolade": "2021 Vitis Vinifera, Gold"
            },
            {
                "accolade": "2021 Merit Wine and Spirit Challenge, Double Award"
            },
            {
                "accolade": "2021 Rosé Rocks, Gold"
            }
        ],
        "alchohol": "12%",
        "year": "2023",
        "description": "A crisp off-dry wine, with a fruity finish.",
        "type": "red",
        "cultivar": "Chardonnay",
        "tasting": "A Medium-dry and elegant blend of Chardonnay and Pinot Noir that shows upfront lime on the nose with subtle undertones of strawberry. The gentle finish lingers forever.",
        "price": {
            "$numberDecimal": "13.50"
        },
        "stock": 44,
        "inStock": true,
        "id": "r001"
    },
    {
        "_id": {
            "$oid": "641c42757cc20f532b60adbe"
        },
        "name": "Van Loveren Blanc de Noir Red Muscadel",
        "image": "/Images/r002.jpg",
        "gallery": {
            "front": "/Images/r002.jpg",
            "close": "/Images/r002-close.jpg"
        },
        "pairing": "The perfect wine with spiced food. Try with curry and Thai dishes. Also a great aperitif.",
        "accolades": [
            {
                "accolade": "2020 Vitis Vinifera, Gold"
            }
        ],
        "alchohol": "11,5%",
        "description": "A fruity, semi-sweet Blanc de Noir.",
        "year": "2022",
        "type": "red",
        "cultivar": "Muscadel",
        "tasting": "Irresistibly fruity, this wine has mouth-watering ﬂavours of raspberry and wild strawberry with abundant notes of rose petal.",
        "price": {
            "$numberDecimal": "10.50"
        },
        "stock": 40,
        "inStock": true,
        "id": "r002"
    },
    {
        "_id": {
            "$oid": "641c42937cc20f532b60adbf"
        },
        "name": "Van Loveren African Java Pinotage",
        "image": "/Images/r003.jpg",
        "gallery": {
            "front": "/Images/r003.jpg",
            "close": "/Images/r003-close.jpg"
        },
        "description": "A smooth, lightly oaked dry red wine.",
        "pairing": "Ideal with hearty red meat dishes such as Osso Bucco, lamb, barbecues, curry, game birds, spare ribs and meat stews.",
        "accolades": [
            {
                "accolade": "2022 Michelangelo International Wine and Spirits Awards, Gold"
            },
            {
                "accolade": "2021 Vitis Vinifera Awards, Gold"
            },
            {
                "accolade": "2021 Michelangelo Wine Awards, Gold"
            }
        ],
        "alchohol": "13,5%",
        "year": "2022",
        "type": "red",
        "cultivar": "Pinotage",
        "tasting": "This stunning oak-matured Pinotage has seductive aromas of roasted coffee beans leading to flavours of blackberry and mulberry with a rich, dark chocolate finish.",
        "price": {
            "$numberDecimal": "14.00"
        },
        "stock": 60,
        "inStock": true,
        "id": "r003"
    },
    {
        "_id": {
            "$oid": "641c42b27cc20f532b60adc0"
        },
        "name": "Christiena Chardonnay",
        "image": "/Images/w001.jpg",
        "gallery": {
            "front": "/Images/w001.jpg",
            "close": "/Images/w001-close.jpg"
        },
        "pairing": "A serious wine to accompany fine cuisine. Good with shellfish, grilled fish, rich chicken dishes and creamy pastas.",
        "accolades": [
            {
                "accolade": "2023 Platter’s Wine Guide awarded 2022, 4 1⁄2 Stars"
            },
            {
                "accolade": "2022 Michelangelo International Wine and Spirits Awards Platinum"
            },
            {
                "accolade": "2022 National Wine Challenge Double Gold"
            },
            {
                "accolade": "2022 Platter’s Wine Guide awarded 2021, 4 1⁄2 Stars"
            },
            {
                "accolade": "2021 National Wine Championship, Double Platinum"
            },
            {
                "accolade": "2021 De Grote Hamersma, Gold"
            },
            {
                "accolade": "2021 James Suckling Awards, 92 points"
            },
            {
                "accolade": "2021 Wine of the Month (25 October) 1st place."
            },
            {
                "accolade": "2021 Decanter Awards, 92/100"
            },
            {
                "accolade": "2021 Platter’s Wine Guide awarded in 2020, 4 1⁄2 Stars"
            }
        ],
        "alchohol": "13,5%",
        "year": "2021",
        "type": "white",
        "cultivar": "Chardonnay",
        "tasting": "A well-balanced, single vineyard wine. Typical citrus and buttery aromas are complimented by toasty oak derived from new French oak barrels.",
        "description": "A premium quality, full-bodied and oak-matured white wine.",
        "price": {
            "$numberDecimal": "20.00"
        },
        "stock": 26,
        "inStock": true,
        "id": "w001"
    },
    {
        "_id": {
            "$oid": "641c42ba7cc20f532b60adc1"
        },
        "name": "Bayede Royal Pinotage",
        "image": "/Images/r004.jpg",
        "gallery": {
            "front": "/Images/r004.jpg",
            "close": "/Images/r004-close.jpg"
        },
        "pairing": "Delicious with light meat dishes, venison, steak and pasta.",
        "accolades": [
            {
                "accolade": "Vitis Vinifera Awards 2021 – Gold."
            }
        ],
        "bundles": [
            {
                "wine": "Wine1"
            },
            {
                "wine": "Wine2"
            },
            {
                "wine": "Wine3"
            },
            {
                "wine": "Wine4"
            },
            {
                "wine": "Wine5"
            }
        ],
        "history": "Bayede! Is a Majority Black owned company and was formed in 2008 when the Zulu king, HM king Goodwill Zwelithini joined forces with Antoinette Vermooten, Co Founder to create jobs in South Africa by developing the first Royal TM, Bayede! For Africa. We manufacture wines, spirits, olive oil, coffee and focus on Kenya and Africa as our export focus. We have supply chain and quality assurance in place. All our products FMCG have the TM Bayede! And by adding value we create sustainable jobs. Our brand present the Royal Crest of the Zulu Royal household and tell a story of 200 years of history when the great king Shaka shouted Bayede! To unite the nations. ",
        "year": "2021",
        "alchohol": "14%",
        "type": "red",
        "cultivar": "Pinotage",
        "tasting": "Dark ruby in color, this wine offers aromas of ripe plums, cherries, chocolate and a slight scent of dried banana, all wrapped in sweet , oaky vanilla. Dry, supple tannins add a silky mouth-feel of black cherries.",
        "description": "Dry wooded red, with a silky finish.",
        "price": {
            "$numberDecimal": "13.00"
        },
        "stock": 16,
        "inStock": true,
        "id": "r004"
    },
    {
        "_id": {
            "$oid": "641c42c17cc20f532b60adc2"
        },
        "name": "Five's Reserve Chenin Blanc",
        "image": "/Images/w002.jpg",
        "gallery": {
            "front": "/Images/w002.jpg",
            "close": "/Images/w002-close.jpg"
        },
        "pairing": "Enjoy with Cape Malay curry, grilled fish, glazed gammon and Vindaloo curries.",
        "accolades": [
            {
                "accolade": "2019 Gold Wine Awards, Gold."
            }
        ],
        "history": "The Five's Reserve range is made as a tribute to Africa's big game animals - the leopard, buffalo, lion, rhino and elephant. The source of inspiration to many a traveller to visit this beautiful continent.",
        "year": "2023",
        "alchohol": "12,5%",
        "type": "white",
        "cultivar": "Chennin Blanc",
        "tasting": "Typical varietal guava and tropical fruit on the nose with rich fruit filled, crisp palate and lingering finish.",
        "description": "A crips fruit-driven dry white.",
        "price": {
            "$numberDecimal": "11.00"
        },
        "stock": 38,
        "inStock": true,
        "id": "w002"
    },
    {
        "_id": {
            "$oid": "641c42c77cc20f532b60adc3"
        },
        "name": "Five's Reserve Cabernet Sauvignon",
        "image": "/Images/r005.jpg",
        "gallery": {
            "front": "/Images/r005.jpg",
            "close": "/Images/r005-close.jpg"
        },
        "pairing": "This wine matches well with grilled red meats, colder evenings, tomato based dishes and good conversation.",
        "accolades": [
            {
                "accolade": "2022 Platter's Wine Guide - 3 stars"
            },
            {
                "accolade": "2022 Double gold Awards"
            },
            {
                "accolade": "2022 LUMO Awards, Gold"
            },
            {
                "accolade": "2022 Merit Awards Wine & Spirit Challenge, Double Gold"
            },
            {
                "accolade": "2021 Old Mutual Trophy Show, Bronze"
            },
            {
                "accolade": "2021 Veritas Wine Awards, Silver"
            },
            {
                "accolade": "2021 The Trophy Wine Show, Bronze"
            }
        ],
        "alchohol": "14%",
        "history": "The Five's Reserve range is made as a tribute to Africa's big game animals - the leopard, buffalo, lion, rhino and elephant. The source of inspiration to many a traveller to visit this beautiful continent.",
        "year": "2021",
        "type": "red",
        "cultivar": "Cabernet Sauvignon",
        "tasting": "Rich plum and blackberry on the nose, followed by flavours of blackcurrant, dark plums and a soft dark chocolate finish. ",
        "description": "A lightly oaked fruity wine with soft tannins.",
        "price": {
            "$numberDecimal": "12.00"
        },
        "stock": 32,
        "inStock": true,
        "id": "r005"
    },
    {
        "_id": {
            "$oid": "6432917bb91131e95c15613d"
        },
        "name": "Daschbosch Merlot",
        "image": "/Images/r008.jpg",
        "gallery": {
            "front": "/Images/r008.jpg",
            "close": "/Images/r008-close.jpg"
        },
        "pairing": "A splendid companion to Veal Cannelloni.",
        "accolades": [
            {
                "accolade": "2021 Vitis Vinifera - Gold"
            },
            {
                "accolade": "2021 Vitis Vinifera - Top 10 Producer"
            }
        ],
        "alchohol": "14,5%",
        "year": "2021",
        "description": "A lightly oaked and well structured red wine.",
        "type": "red",
        "cultivar": "Merlot",
        "tasting": "This well-structured red wine that brims with succulent black cherry and ripe plum flavours.",
        "price": {
            "$numberDecimal": "14.00"
        },
        "stock": 20,
        "inStock": true,
        "id": "r008"
    },
    {
        "_id": {
            "$oid": "6432917eb91131e95c15613e"
        },
        "name": "Daschbosch Charmat Rosé",
        "image": "/Images/w004.jpg",
        "gallery": {
            "front": "/Images/w004.jpg",
            "close": "/Images/w004-close.jpg"
        },
        "pairing": "Serve & share our food- friendly Charmat during any celebration. A wonderful partner to softer white cheeses, fresh seafood, light desserts and fruits with good acidity like fresh strawberries.",
        "year": "2022",
        "alchohol": "10,5%",
        "description": "An elegant sparkling Rosé.",
        "type": "white",
        "method": "This wine is made from 96% Chenin Blanc and 4% Pinotage in the typical Méthode D'Charmat style.",
        "cultivar": "Chenin Blanc 96% Pinotage 4%",
        "tasting": "The wine is bursting with strawberries and cream. But it’s not just all fruit: there is a slight praline nuttiness in the background, which gives the wine some complexity. On the palate it is crisp and crunch and still full of red fruits. It is both easy drinking yet structured and serious enough to make a fine apéritif.",
        "price": {
            "$numberDecimal": "15.50"
        },
        "stock": 51,
        "inStock": true,
        "id": "w004"
    },
    {
        "_id": {
            "$oid": "64329922b91131e95c156140"
        },
        "name": "Meander Moscato (Pink)",
        "image": "/Images/r006.jpg",
        "gallery": {
            "front": "/Images/r006.jpg",
            "close": "/Images/r006-close.jpg"
        },
        "pairing": "Enjoy with fresh oysters, grilled fish or some chicken.",
        "accolades": [
            {
                "accolade": "2019 Michelangelo Award - Silver"
            }
        ],
        "alchohol": "5,5%",
        "year": "2022",
        "description": "A natural sweet perlé wine.",
        "type": "red",
        "cultivar": "Moscato",
        "tasting": "Meander Moscato is the ideal companion to enjoy a bubbly celebration, right from the moment you hear the pop of the cork. It is a delicious taste of honey, melon and citrus fruits in a wine that is just one bottle of sunshine and truckloads of fun. For enjoyment anywhere when a party needs starting.",
        "price": {
            "$numberDecimal": "8.50"
        },
        "stock": 59,
        "inStock": true,
        "id": "r006"
    },
    {
        "_id": {
            "$oid": "64329947b91131e95c156141"
        },
        "name": "Meander Moscato (White)",
        "image": "/Images/w003.jpg",
        "gallery": {
            "front": "/Images/w003.jpg",
            "close": "/Images/w003-close.jpg"
        },
        "pairing": "Enjoy with fresh oysters, grilled fish or some chicken.",
        "accolades": [
            {
                "accolade": "2019 Michelangelo Award - Silver"
            }
        ],
        "alchohol": "5,5%",
        "year": "2022",
        "description": "A natural sweet perlé wine.",
        "type": "White",
        "cultivar": "Moscato",
        "tasting": "Meander Moscato is the ideal companion to enjoy a bubbly celebration, right from the moment you hear the pop of the cork. It is a delicious taste of honey, melon and citrus fruits in a wine that is just one bottle of sunshine and truckloads of fun. For enjoyment anywhere when a party needs starting.",
        "price": {
            "$numberDecimal": "8.50"
        },
        "stock": 120,
        "inStock": true,
        "id": "w003"
    },
    {
        "_id": {
            "$oid": "64329969b91131e95c156142"
        },
        "name": "Meander Pinotage",
        "image": "/Images/r007.jpg",
        "gallery": {
            "front": "/Images/r007.jpg",
            "close": "/Images/r007-close.jpg"
        },
        "pairing": "A good companion to hearty dishes with Venison, Beef or Lamb Meat.",
        "year": "2021",
        "alchohol": "14%",
        "description": "A well-balanced red, brimming with berry and cherry flavours.",
        "type": "red",
        "cultivar": "Pinotage",
        "tasting": "This Pinotage takes you there with South Africa’s home-grown grape variety that has wooed the world. A juicy, succulent red wine to warm the heart and elevate the spirit, here are flavours of plum and cherries, with a slight hit of percolating mocha and cigar-box, made for anytime enjoyment.",
        "price": {
            "$numberDecimal": "10.50"
        },
        "stock": 40,
        "inStock": true,
        "id": "r007"
    },
    {
        "_id": {
            "$oid": "650dec823efd215a1bc61034"
        },
        "name": "Meander Chenin Blanc",
        "image": "/Images/w005.jpg",
        "gallery": {
            "front": "/Images/w005.jpg",
            "close": "/Images/w005-close.jpg"
        },
        "pairing": "Serve chilled and enjoy with grilled fish, oysters or light summer meals.",
        "accolades": [
            {
                "accolade": "2023 Ultra Value Wine Challenge, Gold."
            }
        ],
        "year": "2023",
        "alchohol": "12,85%",
        "type": "white",
        "cultivar": "Chennin Blanc",
        "tasting": "Flavours of pear and melon offer a lemony citrus zest, made for anytime enjoyment.",
        "description": "A fresh, crisp and vibrant white wine.",
        "price": {
            "$numberDecimal": "11.00"
        },
        "stock": 46,
        "inStock": true,
        "id": "w005"
    },
    {
        "_id": {
            "$oid": "650dec9b3efd215a1bc61035"
        },
        "name": "Daschbosch Sauvignon Blanc Brut",
        "image": "/Images/w006.jpg",
        "gallery": {
            "front": "/Images/w006.jpg",
            "close": "/Images/w006-close.jpg"
        },
        "pairing": "The depth and balance of this wine means that one would get great enjoyment from drinking it on its own, however, it will truly come to life when enjoyed with summer fare. A splendid companion to summer salads, light seafood dishes and fresh oysters.",
        "year": "2022",
        "alchohol": "12,5%",
        "type": "white",
        "cultivar": "Sauvignon Blanc",
        "tasting": "Lively, fresh and uncomplicated with forthcoming notes of green pepper and herbs. Elegant capsicum and floral aromas in waves of the finest bubbles.",
        "description": "A lively and fresh brut with a bubbling floral finish.",
        "price": {
            "$numberDecimal": "14.50"
        },
        "stock": 50,
        "inStock": true,
        "id": "w006"
    },
    {
        "_id": {
            "$oid": "650decb03efd215a1bc61036"
        },
        "name": "Van Loveren Neil’s Pick Colombar",
        "image": "/Images/w007.jpg",
        "gallery": {
            "front": "/Images/w007.jpg",
            "close": "/Images/w007-close.jpg"
        },
        "pairing": "Enjoy with poultry, duck, light stews or simply on its own.",
        "accolades": [
            {
                "accolade": "2021 Michelangelo Wine Awards, Gold."
            }
        ],
        "year": "2023",
        "alchohol": "10,5%",
        "type": "white",
        "cultivar": "Colombar",
        "tasting": "The wine features aromatic guava flavours, with a fruity, off-dry finish.",
        "description": "A fruit-driven, off-dry white wine.",
        "price": {
            "$numberDecimal": "12.00"
        },
        "stock": 35,
        "inStock": true,
        "id": "w007"
    },
    {
        "_id": {
            "$oid": "650decbb3efd215a1bc61037"
        },
        "name": "Skyline Late Harvest",
        "image": "/Images/w008.jpg",
        "gallery": {
            "front": "/Images/w008.jpg",
            "close": "/Images/w008-close.jpg"
        },
        "pairing": "Excellent with cheesecake, crème caramel and baked custard fruit-based desserts such as apricot & peach tarts, glazed fruit tartlets or pavlova and summer fruit tarts.",
        "year": "2022",
        "alchohol": "11,2%",
        "type": "white",
        "cultivar": "Chennin Blanc",
        "tasting": "Elegant aromas of dried apricot, peaches, honey and floral notes. Full and rich, bursting with flavours of dried fruit and rounded off with a nice acidity to keep it fresh.",
        "description": "A full bodied semi sweet wine.",
        "price": {
            "$numberDecimal": "11.00"
        },
        "stock": 48,
        "inStock": true,
        "id": "w008"
    },
    {
        "_id": {
            "$oid": "650decc03efd215a1bc61038"
        },
        "name": "Van Loveren Red Muscadel",
        "image": "/Images/r009.jpg",
        "gallery": {
            "front": "/Images/r009.jpg",
            "close": "/Images/r009-close.jpg"
        },
        "pairing": "The perfect ending to a fine meal. Serve with cheese and fruit, or on ice as an aperitif on hot summer days.",
        "accolades": [
            {
                "accolade": "2020 Michelangelo International Wine & Spirit Awards, Double Gold."
            }
        ],
        "year": "2022",
        "alchohol": "16,5%",
        "type": "white",
        "cultivar": "Red Muscadel",
        "tasting": "A full-sweet, fortified wine with abundant raisin and muscat flavours. Opulent lingering finish.",
        "description": "Full-sweet dessert wine.",
        "price": {
            "$numberDecimal": "16.00"
        },
        "stock": 42,
        "inStock": true,
        "id": "r009"
    },
    {
        "_id": {
            "$oid": "650decc03efd215a1bc61038"
        },
        "name": "Five's Reserve Merlot Rosé",
        "image": "/Images/r010.jpg",
        "gallery": {
            "front": "/Images/r010.jpg",
            "close": "/Images/r010-close.jpg"
        },
        "pairing": "A lovely summer wine to enjoy with salmon, crayfish, cooked ham, vegetable moussaka and ratatouille.",
        "accolades": [
            {
                "accolade": "2020 Rosé Rocks, Gold"
            },
            {
                "accolade": "2019 Gold Wine Awards, Gold"
            }
        ],
        "year": "2023",
        "alchohol": "12,5%",
        "type": "red",
        "cultivar": "Merlot",
        "tasting": "A full flavoured dry rosé with a fruit-driven nose, abundance of strawberries and ripe summer berries on the palate complimented by subtle hints of violets and spice and a gentle, lingering finish.",
        "description": "Fruit-driven dry rosé wine.",
        "price": {
            "$numberDecimal": "11.00"
        },
        "stock": 62,
        "inStock": true,
        "id": "r010"
    },
    {
        "_id": {
            "$oid": "66808decb120da8b4d8b5090"
        },
        "name": "Meander Moscato (White) - 275ml",
        "image": "/Images/w009.jpg",
        "gallery": {
            "front": "/Images/w009.jpg",
            "close": "/Images/w009-close.jpg"
        },
        "pairing": "Enjoy with fresh oysters, grilled fish or some chicken.",
        "accolades": [
            {
                "accolade": "2019 Michelangelo Award - Silver"
            }
        ],
        "alchohol": "5,5%",
        "year": "2023",
        "description": "A natural sweet, fizzy low alcohol wine.",
        "type": "White",
        "cultivar": "Moscato",
        "tasting": "Meander Moscato is the ideal companion to enjoy a bubbly celebration, right from the moment you hear the pop of the cork. It is a delicious taste of honey, melon and citrus fruits in a wine that is just one bottle of sunshine and truckloads of fun. For enjoyment anywhere when a party needs starting.",
        "price": {
            "$numberDecimal": "3.50"
        },
        "stock": 285,
        "inStock": true,
        "id": "w009",
        "index": "02"
    },
    {
        "_id": {
            "$oid": "66808e1fb120da8b4d8b5091"
        },
        "name": "Du Preez Sauvignon Blanc",
        "image": "/Images/w010.jpg",
        "gallery": {
            "front": "/Images/w010.jpg",
            "close": "/Images/w010-close.jpg"
        },
        "pairing": "For a perfect pairing, savour this Sauvignon Blanc with fresh seafood dishes like grilled prawns, citrusy paella, or herb-infused grilled chicken.",
        "history": "The exceptional Du Preez Sauvignon Blanc has secured a prestigious title at the 2023 AWC Vienna Wine Challenge! It's now the Trophy Winner in the \" Sauvignon Blanc 13,0 vol% Alk \" category, and what's even more remarkable is that it's the sole Trophy winner from South Africa!",
        "alchohol": "13%",
        "year": "2023",
        "description": "A dry white, with strong hints of tropical fruit.",
        "type": "white",
        "cultivar": "Sauvignon Blanc",
        "tasting": "The tropical and guava notes carry through to the palate, accompanied by refreshing grassy hints. Additional flavours of ripe fig add depth and a smooth, crisp finish leaves a refreshing impression.",
        "price": {
            "$numberDecimal": "13.50"
        },
        "stock": 114,
        "inStock": true,
        "id": "w010",
        "index": "01"
    },
    {
        "_id": {
            "$oid": "66808ea6b120da8b4d8b5092"
        },
        "name": "Meander Moscato (Pink) - 275ml",
        "image": "/Images/r011.jpg",
        "gallery": {
            "front": "/Images/r011.jpg",
            "close": "/Images/r011-close.jpg"
        },
        "pairing": "Enjoy with fresh oysters, grilled fish or some chicken.",
        "accolades": [
            {
                "accolade": "2019 Michelangelo Award - Silver"
            }
        ],
        "alchohol": "5,5%",
        "year": "2023",
        "description": "A natural sweet, fizzy low alcohol wine.",
        "type": "red",
        "cultivar": "Moscato",
        "tasting": "Meander Moscato is the ideal companion to enjoy a bubbly celebration, right from the moment you hear the pop of the cork. It is a delicious taste of honey, melon and citrus fruits in a wine that is just one bottle of sunshine and truckloads of fun. For enjoyment anywhere when a party needs starting.",
        "price": {
            "$numberDecimal": "3.50"
        },
        "stock": 477,
        "inStock": true,
        "id": "r011",
        "index": "03"
    },
    {
        "_id": {
            "$oid": "66808ebcb120da8b4d8b5093"
        },
        "name": "Meander Shiraz",
        "image": "/Images/r012.jpg",
        "gallery": {
            "front": "/Images/r012.jpg",
            "close": "/Images/r012-close.jpg"
        },
        "pairing": "A good companion to almost any food, including pasta, red meat or chicken dishes.",
        "year": "2021",
        "alchohol": "13.5%",
        "description": "A vibrant red wine with flavours of blackcurrant and spices.",
        "type": "red",
        "cultivar": "Shiraz",
        "tasting": "This Shiraz takes you there with a straight-forward wine that is made for adventure and comfort. Awesome flavours of blackcurrant and dark purple figs are lifted with an exotic hit of white pepper and all-spice, made for anytime enjoyment.",
        "price": {
            "$numberDecimal": "11.50"
        },
        "stock": 92,
        "inStock": true,
        "id": "r012",
        "index": "04"
    },
    {
        "_id": {
            "$oid": "66808ecbb120da8b4d8b5094"
        },
        "name": "Kirabo Shiraz",
        "image": "/Images/r013.jpg",
        "gallery": {
            "front": "/Images/r013.jpg",
            "close": "/Images/r013-close.jpg"
        },
        "pairing": "Pair this captivating Shiraz with hearty dishes like grilled steak, slow-cooked stews, or rich pasta with meat sauces, as its bold and concentrated dark fruit flavours and smooth tannins create a luxurious and velvety mouthfeel, making it a perfect match for robust and flavourful meals.",
        "method": "This Shiraz has been aged for 24 months in old oak barrels, allowing it to develop complexity and integrate flavors from the wood.",
        "alchohol": "15.5%",
        "year": "2020",
        "description": "A complex and intense, oak aged Shiraz.",
        "type": "red",
        "cultivar": "Shiraz",
        "tasting": "This Shiraz tantalizes the senses with its concentrated dark fruit aromas. Rich notes of blackberry and blackcurrant dominate, creating an intense and alluring bouquet. A touch of smokiness adds depth, while a hint of spiciness adds complexity to the aromatic profile. On the palate, this full-bodied Shiraz impresses with its bold and robust character. The concentrated flavors of blackberry and blackcurrant carry over, enveloping the taste buds with their luscious and ripe essence. The smooth tannins glide seamlessly, providing a luxurious and velvety mouthfeel.",
        "price": {
            "$numberDecimal": "16.00"
        },
        "stock": 55,
        "inStock": true,
        "id": "r013",
        "index": "05"
    },
    {
        "_id": {
            "$oid": "650decc03efd215a1bc61038"
        },
        "name": "Five's Reserve Chardonnay",
        "image": "/Images/w011.jpg",
        "gallery": {
            "front": "/Images/w011.jpg",
            "close": "/Images/w011-close.jpg"
        },
        "pairing": "Best enjoy with roast chicken, seafood or a creamy pasta dish.",
        "year": "2023",
        "alchohol": "13,5%",
        "type": "white",
        "cultivar": "Chardonnay",
        "tasting": "The lovely aroma of butterscotch and crème brûlée is balanced by the subtle intensity of fruit in this Chardonnay. The palate is round creamy and full - making every glass of this wine as enjoyable as the next.",
        "description": "A rich and creamy, light wooded Chardonnay",
        "price": {
            "$numberDecimal": "12.00"
        },
        "stock": 62,
        "inStock": true,
        "id": "w011"
    },
    {
        "_id": {
            "$oid": "650decc03efd215a1bc61038"
        },
        "name": "Van Loveren Sauvignon Blanc",
        "image": "/Images/w012.jpg",
        "gallery": {
            "front": "/Images/w012.jpg",
            "close": "/Images/w012-close.jpg"
        },
        "pairing": "Enjoy with any seafood or white meat dish such as seared tuna, seafood risotto or roast pork.",
        "accolades": [
            {
                "accolade": "2023 National Wine Challenge, Double Gold"
            },
            {
                "accolade": "2023 Platter’s Wine Guide (awarded in 2022), 3 Stars"
            },
            {
                "accolade": "2022 Tim Atkin MW South Africa Special Report, 90 points"
            },
            {
                "accolade": "2022 Michelangelo International Wine & Spirits Awards, Gold"
            },
            {
                "accolade": "2022 Vitis Vinifera Awards, Gold"
            },
            {
                "accolade": "2022 Merit Wine & Spirit Challenge, Double Gold"
            }
        ],
        "year": "2023",
        "alchohol": "12,5%",
        "type": "white",
        "cultivar": "Sauvignon Blanc",
        "tasting": "With crunchy green apple on the nose, this fruit-driven Sauvignon Blanc shows gooseberry, lime and grapefruit with a touch of minerality on the finish.",
        "description": "A fruit-driven, dry white wine.",
        "price": {
            "$numberDecimal": "13.50"
        },
        "stock": 62,
        "inStock": true,
        "id": "w012"
    },
    {
        "_id": {
            "$oid": "650decc03efd215a1bc61038"
        },
        "name": "Bayede Cabernet Sauvignon",
        "image": "/Images/r014.jpg",
        "gallery": {
            "front": "/Images/r014.jpg",
            "close": "/Images/r014-close.jpg"
        },
        "pairing": "Pasta, red meat and tuna and similar fish dishes.",
        "accolades": [
            {
                "accolade": "2022 Michelangelo International Wine Awards, Gold"
            },
            {
                "accolade": "2021 Veritas Wine Awards, Silver"
            }
        ],
        "year": "2023",
        "alchohol": "14%",
        "type": "red",
        "cultivar": "Cabernet Sauvignon",
        "tasting": "Upfront berries with delightful cherry overtones. Ripe fruit on the palate with a powerful finish. Typical blackcurrant and plum flavours compliment the well integrated slightly wooded oak tannins.",
        "description": "A slightly oaked, medium-bodied dry red wine.",
        "price": {
            "$numberDecimal": "13.50"
        },
        "stock": 62,
        "inStock": true,
        "id": "r014"
    },
    {
        "_id": {
            "$oid": "655926977410e337a9d85428"
        },
        "name": "Festive Bundle (12 Bottles)",
        "image": "/Images/bundle1.jpeg",
        "year": "2024",
        "accolades": [
            {
                "accolade": "2022 Merit Awards Wine & Spirit Challenge, Double Gold"
            },
            {
                "accolade": "2023 Platter’s Wine Guide awarded 2022, 4 1⁄2 Stars"
            },
            {
                "accolade": "2022 Michelangelo International Wine and Spirits Awards Platinum"
            },
            {
                "accolade": "2022 National Wine Challenge Double Gold"
            },
            {
                "accolade": "2022 Platter’s Wine Guide awarded 2021, 4 1⁄2 Stars"
            },
            {
                "accolade": "2022 Platter's Wine Guide - 3 stars"
            },
            {
                "accolade": "2022 Double gold Awards"
            },
            {
                "accolade": "2022 LUMO Awards, Gold"
            },
            {
                "accolade": "2022 Merit Awards Wine & Spirit Challenge, Double Gold"
            },
            {
                "accolade": "2023 Ultra Value Wine Challenge, Gold."
            }
        ],
        "gallery": {
            "close": "/Images/bundle1-close.jpeg"
        },
        "bundles": [
            {
                "wine": "Meander Chenin Blanc"
            },
            {
                "wine": "Van Loveren Daydream Chardonnay Pinot Noir"
            },
            {
                "wine": "Meander Moscato (Pink)"
            },
            {
                "wine": "Meander Moscato (White)"
            },
            {
                "wine": "Meander Pinotage"
            },
            {
                "wine": "Daschbosch Merlot"
            },
            {
                "wine": "Five's Reserve Chardonnay"
            },
            {
                "wine": "Skyline Late Harvest"
            },
            {
                "wine": "Van Loveren Sauvignon Blanc"
            },
            {
                "wine": "Five's Reserve Merlot Rosé"
            },
            {
                "wine": "Meander Shiraz"
            },
            {
                "wine": "Bayede Cabernet Sauvignon"
            }
        ],
        "description": "A wide selection of wines, perfect for the festive season.",
        "price": {
            "$numberDecimal": "110.00"
        },
        "stock": 50,
        "inStock": true,
        "id": "bundle1"
    },
    {
        "_id": {
            "$oid": "655928497410e337a9d85429"
        },
        "name": "Christmas Bundle (6 Bottles)",
        "image": "/Images/bundle2.jpeg",
        "year": "2024",
        "gallery": {
            "close": "/Images/bundle2-close.jpeg"
        },
        "accolades": [
            {
                "accolade": "2022 Platter's Wine Guide - 3 stars"
            },
            {
                "accolade": "2022 Double gold Awards"
            },
            {
                "accolade": "2022 LUMO Awards, Gold"
            },
            {
                "accolade": "2022 Merit Awards Wine & Spirit Challenge, Double Gold"
            }
        ],
        "bundles": [
            {
                "wine": "Daschbosch Sparkling Charmat Rosé"
            },
            {
                "wine": "Daschbosch Sparkling Sauvignon Blanc Brut"
            },
            {
                "wine": "Christiena Chardonnay"
            },
            {
                "wine": "Du Preez Sauvignon Blanc"
            },
            {
                "wine": "Dascbosch Merlot"
            },
            {
                "wine": "Van Loveren Red Muscadel"
            }
        ],
        "description": "All the wine you'll need for Christmas Day.",
        "price": {
            "$numberDecimal": "75.00"
        },
        "stock": 50,
        "inStock": true,
        "id": "bundle2"
    },
    {
        "_id": {
            "$oid": "66808ea6b120da8b4d8b5092"
        },
        "name": "Meander Moscato (White) - 12 x 275ml",
        "image": "/Images/r009.jpg",
        "gallery": {
            "front": "/Images/r009.jpg",
            "close": "/Images/r009-close.jpg"
        },
        "pairing": "Enjoy with fresh oysters, grilled fish or some chicken.",
        "accolades": [
            {
                "accolade": "2019 Michelangelo Award - Silver"
            }
        ],
        "alchohol": "5,5%",
        "year": "2024",
        "description": "A natural sweet, fizzy low alcohol wine.",
        "type": "red",
        "cultivar": "Moscato",
        "tasting": "Meander Moscato is the ideal companion to enjoy a bubbly celebration, right from the moment you hear the pop of the cork. It is a delicious taste of honey, melon and citrus fruits in a wine that is just one bottle of sunshine and truckloads of fun. For enjoyment anywhere when a party needs starting.",
        "price": {
            "$numberDecimal": "30.00"
        },
        "stock": 100,
        "inStock": true,
        "id": "bundle3"
    },
    {
        "_id": {
            "$oid": "66808ea6b120da8b4d8b5092"
        },
        "name": "Meander Moscato (Pink) - 12 x 275ml",
        "image": "/Images/r011.jpg",
        "gallery": {
            "front": "/Images/r011.jpg",
            "close": "/Images/r011-close.jpg"
        },
        "pairing": "Enjoy with fresh oysters, grilled fish or some chicken.",
        "accolades": [
            {
                "accolade": "2019 Michelangelo Award - Silver"
            }
        ],
        "alchohol": "5,5%",
        "year": "2024",
        "description": "A natural sweet, fizzy low alcohol wine.",
        "type": "red",
        "cultivar": "Moscato",
        "tasting": "Meander Moscato is the ideal companion to enjoy a bubbly celebration, right from the moment you hear the pop of the cork. It is a delicious taste of honey, melon and citrus fruits in a wine that is just one bottle of sunshine and truckloads of fun. For enjoyment anywhere when a party needs starting.",
        "price": {
            "$numberDecimal": "30.00"
        },
        "stock": 100,
        "inStock": true,
        "id": "bundle4"
    }

]

export default function GetProductData(id) {

    let productData = productsArray.find(product => product.id === id);

    return productData;
}