import { React, useState, useContext, useEffect, useRef } from "react";
import axios from "axios";
import { CartContext } from "../../Context/CartContext";
import Button from "react-bootstrap/Button"
import Spinner from 'react-bootstrap/Spinner';
import {
    PayPalScriptProvider,
    PayPalButtons,
    PayPalHostedFieldsProvider,
    PayPalHostedField,
    usePayPalHostedFields
} from "@paypal/react-paypal-js"
import "./paypalCheckoutButton.css"
import Form from "react-bootstrap/Form"
import GetProductData from "../Shop/getProductData";


const clientId = process.env.REACT_APP_CLIENT_ID

// Example of custom component to handle form submit
const SubmitPayment = (email, phone, time, instructions) => {
    const [paying, setPaying] = useState(false);
    const cardHolderName = useRef(null);
    const hostedField = usePayPalHostedFields();

    const handleClick = () => {
        if (hostedField) {
            if (
                Object.values(hostedField.cardFields.getState().fields).some(
                    (field) => !field.isValid
                ) ||
                !cardHolderName?.current?.value
            ) {
                return alert(
                    "The card details entered are invalid, please ensure that the details are entered correctly and retry the payment"
                );
            }
            setPaying(true);
            hostedField.cardFields
                .submit({
                    cardholderName: cardHolderName?.current?.value,
                })
                .then((data) => {
                    // Order is captured on the server and the response is returned to the browser
                    return fetch(`/api/Checkout/Orders/${data.orderID}/Capture`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            orderID: data.orderId,
                            email: email,
                            phone: phone,
                            time: time,
                            instructions: instructions
                        })
                    })
                        .then((response) => response.json())
                        .then((orderData) => {
                            window.location.href = `/Checkout/Success`;
                        })
                        .finally(() => {
                            setPaying(false);
                        });
                })
                .catch((err) => {
                    alert("An error has occured during your payment. \n\nPlease check that the email adress and payment details you entered are correct and try again. \n\nIf the error persists please contact us.");
                    setPaying(false);
                });
        }
    };

    return (
        <>
            <Form>
                <Form.Label title="This represents the full name as shown in the card">
                    Card Holder Name
                    <Form.Control
                        id="card-holder"
                        ref={cardHolderName}
                        className="card-field"
                        type="text"
                        placeholder="Full name"
                    />
                </Form.Label>
                <br></br>
                <br></br>
            </Form>
            <Button
                className="checkoutButton"
                onClick={handleClick}
                type="Submit"
            >
                {paying ?
                    <>
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                        Processing...
                    </>
                    :
                    "PAY"
                }
            </Button >
        </>
    );
};


export default function PaypalCheckoutButton(props) {

    const [clientToken, setClientToken] = useState(null);
    const cart = useContext(CartContext);
    const checkoutTotal = cart.getTotalCost().toFixed(2);
    const productsCount = cart.items.reduce((sum, product) => sum + product.quantity, 0);
    let specialShipping = false;
    for (let i = 0; i < cart.items.length; i++) {
        if (cart.items[i].id === "bundle1" || cart.items[i].id === "bundle2" || cart.items[i].id === "bundle3" || cart.items[i].id === "bundle4") {
            specialShipping = true;
        }

    }
    let shippingCost;
    if (productsCount >= 6 || specialShipping === true) {
        shippingCost = 0.00;
    }
    else {
        shippingCost = 5.00;
    }
    const totalCost = (parseFloat(shippingCost) + parseFloat(checkoutTotal)).toFixed(2);
    const items = cart.items.map((item) => ({
        name: GetProductData(item.id).name,
        quantity: item.quantity.toString(),
        unit_amount: {
            currency_code: "GBP",
            value: GetProductData(item.id).price.$numberDecimal.toString()
        },
    }));

    let full_name = (props.firstName + " " + props.lastName);
    let email = props.email;
    let phone = props.phone;
    let address_line_1 = props.address1;
    let address_line_2 = props.address2;
    let admin_area_1 = props.area1;
    let admin_area_2 = props.area2;
    let postal_code = props.postcode;
    let time = props.time;
    let instructions = props.instructions;

    useEffect(() => {
        axios
            .get("/api/Checkout")
            .then((res) => {
                setClientToken(res.data);
            });
    }, []);


    const createOrder = (data, actions) => {
        // Order is created on the server and the order id is returned
        return fetch("/api/Checkout/Orders", {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                purchase_units: [{
                    items: items,
                    amount: {
                        currency_code: "GBP",
                        value: totalCost,
                        breakdown: {
                            item_total: {
                                currency_code: "GBP",
                                value: checkoutTotal,
                            },
                            shipping: {
                                currency_code: "GBP",
                                value: shippingCost,
                            },
                        }
                    },
                    shipping: {
                        type: "SHIPPING",
                        name: {
                            full_name: full_name
                        },
                        address: {
                            address_line_1: address_line_1,
                            address_line_2: address_line_2,
                            admin_area_1: admin_area_1,
                            admin_area_2: admin_area_2,
                            postal_code: postal_code,
                            country_code: "GB"
                        }
                    }
                }],
                payment_source: {
                    paypal: {
                        experience_context: {
                            landing_page: "LOGIN",
                            user_action: "PAY_NOW",
                            payment_method_preference: "IMMEDIATE_PAYMENT_REQUIRED",
                            cancel_url: "/Checkout",
                        }
                    }
                },
                intent: "CAPTURE",
            }),
        })
            .then((response) => response.json())
            .then((order) => order.id);
    };

    const onApprove = (data, actions) => {
        // Order is captured on the server and the response is returned to the browser
        return fetch(`/api/Checkout/Orders/${data.orderID}/Capture`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                orderID: data.orderId,
                email: {
                    email: email,
                    phone: phone,
                    time: time,
                    instructions: instructions
                }
            })
        })
            .then((response) => response.json())
            .then((orderData) => {
                window.location.href = `/Checkout/Success`;
            })
            .catch((err) => {
                alert("An error has occured during your payment. \n\nPlease check that the email adress and payment details you entered are correct and try again. \n\nIf the error persists please contact us.");
            });
    };

    return (
        <>
            {clientToken ? (
                <PayPalScriptProvider options={{
                    clientId: clientId,
                    dataClientToken: clientToken,
                    components: "buttons,hosted-fields",
                    currency: "GBP",
                    debug: true,
                    disableFunding: ["venmo", "paylater"],
                    intent: "capture",
                    locale: "en_GB",
                    vault: false,
                }}>
                    <PayPalButtons
                        createOrder={(data) => createOrder(data)}
                        onApprove={(data) => onApprove(data)}
                    />
                    <h4>or</h4>
                    <h2>Debit or Credit Card:</h2>
                    <PayPalHostedFieldsProvider
                        createOrder={(data) => createOrder(data)}
                    >
                        <label htmlFor="card-number">
                            Card Number
                        </label>
                        <PayPalHostedField
                            id="card-number"
                            className="card-field"
                            hostedFieldType="number"
                            options={{
                                selector: "#card-number",
                                placeholder: "1111 1111 1111 1111",
                            }}
                        />
                        <label htmlFor="cvv">
                            CVV
                        </label>
                        <PayPalHostedField
                            id="cvv"
                            className="card-field cvv"
                            hostedFieldType="cvv"
                            options={{
                                selector: "#cvv",
                                placeholder: "123",
                                maskInput: true,
                            }}
                        />
                        <label htmlFor="expiration-date">
                            Expiration Date
                        </label>
                        <PayPalHostedField
                            id="expiration-date"
                            className="card-field date"
                            hostedFieldType="expirationDate"
                            options={{
                                selector: "#expiration-date",
                                placeholder: "MM/YYYY",
                            }}
                        />
                        <SubmitPayment
                            email={email}
                            phone={phone}
                            time={time}
                            instructions={instructions}
                        />
                    </PayPalHostedFieldsProvider>
                </PayPalScriptProvider >
            ) : (
                <h1>Loading Payment Token...</h1>
            )
            }
        </ >
    );
}